@import "../styles/variables.scss";

.modal-button {
  margin: 0px;
  max-width: 140px;
  width: auto;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.modal {
  // background-color: $lightGray;
  position: absolute;
  padding: 80px;
  // top: 80px;
  left: 50%;
  // right: auto;
  // bottom: auto;
  // // margin-right: -50%;
  transform: translate(-50%, 0);
  // border-radius: 6px;
}

.modal-overlay {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-color: rebeccapurple;
  background-color: rgba(18, 18, 21, 0.7);
  overflow-y: auto;
  height: 100%;
}

.modal-content {
  background-color: $lightGray;
  border-radius: 4px;
  padding: 18px 12px;
  width: 800px;
}

.selectable {
  border-radius: 4px;
  background-color: red;
  color: $brandPrimary;
  padding: 16px;
  width: fit-content;
  cursor: pointer;
}

.selectable.selected {
  background-color: $brandPrimary;
  color: $brandSecondary;
}

.edit-button {
  margin: 0;
  border: none;
  background-color: transparent;
  color: $brandPrimary;
  padding: 6px;
}
