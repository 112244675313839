@import "../styles/variables.scss";

.companies-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 12px 0;
  background-color: $brandSecondary;
}
