/*
For bold:
font-weight: 700
*/

@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@400;700&family=Bitter:wght@400;700&display=swap");

body {
  font-family: "Albert Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: $brandBlack;
}

a {
  &:hover {
    color: $hoverAccent;
  }
}

.body2 {
  font-size: 16px;
  line-height: 24px;
}

.body3 {
  font-size: 15px;
  line-height: 24px;
}

h1 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 64px;
  @media screen and (max-width: 768px) {
    font-size: 38px;
    line-height: 44px;
  }
}

h2 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 52px;
  margin: 1em 0;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
    margin: 0.5em 0;
  }
}

h3 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
}

h4 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  margin: 1em 0;
  @media screen and (max-width: 768px) {
    font-size: 21px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
}

a {
  text-decoration: none;
  color: $brandBlack;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}
