@import "variables.scss";

.button-l {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // gap: 8px;

  cursor: pointer;

  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;

  border: 0px solid $brandPrimary;

  background-color: $brandPrimary;
  &:hover {
    background-color: $hoverPrimary;
    border-color: $hoverPrimary;
    color: #ffffff;
  }
  &:disabled {
    background-color: $lightGray;
    border-color: $lightGray;
  }

  font-size: 18px;
  line-height: 24px;
  padding: 24px 32px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 20px 24px;
  }
}

.button-m {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  text-align: center;

  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // gap: 8px;

  cursor: pointer;

  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;

  border: 0px solid $brandPrimary;

  background-color: $brandPrimary;
  &:hover {
    background-color: $hoverPrimary;
    border-color: $hoverPrimary;
    color: #ffffff;
  }
  &:disabled {
    background-color: $lightGray;
    border-color: $lightGray;
  }

  font-size: 16px;
  line-height: 24px;
  padding: 20px 24px;
}

.button-s {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  text-align: center;

  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // gap: 8px;

  cursor: pointer;

  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;

  border: 0px solid $brandPrimary;

  background-color: $brandPrimary;
  &:hover {
    // background-color: $hoverPrimary;
    // border-color: $hoverPrimary;
    color: $brandAccent;
  }
  &:disabled {
    background-color: $darkGray;
    border-color: $darkGray;
  }

  font-size: 14px;
  line-height: 16px;
  padding: 16px 24px;
}

.outlined {
  background-color: transparent;
  border: 1px solid $brandSecondary;
  color: $brandSecondary;
  &:hover {
    border-color: $hoverAccent;
    color: $hoverAccent;
    background-color: transparent;
  }
  &:disabled {
    border-color: $lightGray;
  }
}

.outlined-dark {
  background-color: transparent;
  border: 1px solid $lightGray;
  color: $lightGray;
  &:hover {
    border-color: $hoverPrimary;
    background-color: $hoverPrimary;
  }
  &:disabled {
    border-color: $lightGray;
  }
}
