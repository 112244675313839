@import "../styles/variables.scss";

.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-button {
  margin-bottom: 12px;
}

.modal {
  // background-color: $lightGray;
  position: absolute;
  padding: 80px;
  // top: 80px;
  left: 50%;
  // right: auto;
  // bottom: auto;
  // // margin-right: -50%;
  transform: translate(-50%, 0);
  // border-radius: 6px;
}

.modal-overlay {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-color: rebeccapurple;
  background-color: rgba(18, 18, 21, 0.7);
  overflow-y: auto;
  height: 100%;
}

.content {
  padding: 12px;
  background-color: $lightGray;
  border-radius: 4px;
  padding: 18px 12px;
  width: 800px;
}

.content h3 {
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 12px;
}

.content hr {
  margin: 24px 12px;
}
.input-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-container {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.explanations {
  display: flex;
  flex-direction: row;
}

.explanation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  padding: 0 12px;
  text-align: center;
}

.image {
  max-height: 200px;
}

.selectable {
  border-radius: 4px;
  background-color: red;
  color: $brandPrimary;
  padding: 16px;
  width: fit-content;
  cursor: pointer;
}

.selectable.selected {
  background-color: $brandPrimary;
  color: $brandSecondary;
}
