$background: #ffffff;

$brandPrimary: #121215;
$brandAccent: #c5a37b;
$brandBlack: #121215;
$brandSecondary: #fafafa;

$hoverPrimary: #fafafa;
$hoverSecondary: #121215;
$hoverAccent: #c28642;

$shadePrimary: #f2f6fb;
$shadeSecondary: #fbf7f2;
$shadeAccent: #f5eee6;
$lightGray: #e4e4e7;
$darkGray: #767789;

$fullWhite: #ffffff;

$success: #4cb263;
$info: #0066cc;
$warning: #eeb155;
$error: #e85454;

$homeHeroColor: #b2755e;

$selectedbackground: #d9d9d9;
$selectedShadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

$inputShadow: 0px 0px 4px rgba(0, 102, 204, 0.4);

$navBarHeight: 60px;
