@import "../styles/variables.scss";

.root {
  margin-top: 24px;
  margin-bottom: 24px;
}

.root h3 {
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.container textarea {
  border: none;
  margin: 6px;
  border-radius: 4px;
  background-color: $shadePrimary;
  color: $brandPrimary;
  width: fit-content;
  border-radius: 4px;
  padding: 12px;
  height: 38px;
}

.selectable {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  border-radius: 4px;
  background-color: $shadePrimary;
  color: $brandPrimary;
  padding: 0 12px;
  height: 38px;
  width: fit-content;
  cursor: pointer;
}

.selectable.selected {
  background-color: $brandPrimary;
  color: $brandSecondary;
}
