@import "../styles/variables.scss";

.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: $brandAccent;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
