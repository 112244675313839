@import "../styles/variables.scss";

.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $brandPrimary;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 6px 0;
  justify-content: space-between;
}

.header {
  flex: 1;
  text-align: start;
}

.header h2 {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: $brandSecondary;
}

.content-line {
  font-size: 18px;
  line-height: 20px;
  color: $brandSecondary;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.pending {
  margin-right: 6px;
}

.collected {
  margin-left: 6px;
}

.arrow {
  color: $brandSecondary;
  height: 26px;
  width: 26px;
  margin-left: 24px;
}
