@import "../styles/variables.scss";

.modal-button {
  height: 38px;
  width: 38px;
  padding: 6px;
  border: none;
  border-radius: 4px;
  background-color: $brandPrimary;
  color: $brandSecondary;
  cursor: pointer;
  &:hover {
    color: $brandAccent;
  }
}

.modal-icon {
  height: 100%;
  width: 100%;
}

.modal {
  background-color: $lightGray;
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  padding: 18px;
}

.modal h3 {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;
  margin-bottom: 12px;
}

.modal .line {
  margin-top: 6px;
  margin-bottom: 6px;
}

.modal input[type="checkbox"] {
  margin-right: 12px;
}

.modal select {
  text-align: center;
}

.modal .button {
  margin-top: 12px;
  width: 100%;
}
