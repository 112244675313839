.header {
  h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin: 0;
  }
}

.summary {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
