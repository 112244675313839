@import "variables.scss";
@import "fonts.scss";
@import "buttons.scss";
@import "mobile.scss";
@import "forms.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $shadePrimary;
}

p {
  margin-bottom: 1em;
}

ul {
  list-style: inside;
}

hr {
  // background-color: red;
  border-color: $lightGray;
}

// summary {
//     position: relative;
//     // border: 2.5px inset grey;
//     padding: 6px;
//     margin: 1rem 0;
//     cursor: pointer;
//     // transform: scaleX(-1);
// }

// summary b {
//     display: inline-block;
//     position: absolute;
//     right: 6px;
//     transform: scaleX(-1);
// }

// .details-content {
//     display: flex;
//     justify-content: space-between;
//     width: 96%;
//     margin: 2.5vh auto;
// }

.bg-white {
  background-color: $background;
}

.bg-primary {
  background-color: $brandPrimary !important;
}

.bg-secondary {
  background-color: $brandSecondary !important;
}

.bg-transparent {
  background-color: transparent;
}

.bg-light {
  background-color: $lightGray;
}

.color-primary {
  color: $brandPrimary;
}

.color-white {
  color: $lightGray;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.reverse-row {
  display: flex;
  flex-direction: row-reverse;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.margin-1 {
  margin: 1em;
}

.margin-2 {
  margin: 2em;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 1em;
}

.ml-2 {
  margin-left: 2em;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.padding-1 {
  padding: 1em;
}

.padding-2 {
  padding: 2em;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 1em;
}

.pb-2 {
  padding-bottom: 2em;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 1em;
}

.pl-2 {
  padding-left: 2em;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 1em;
}

.pr-2 {
  padding-right: 2em;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: end;
}

.justify-start {
  justify-content: start;
}

.align-center {
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-end {
  align-self: end;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

*[class^="col-"] {
  display: flex;
  flex-direction: column;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.666666666666667%;
}

.col-10 {
  width: 83.333333333333333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.666666666666667%;
}

.col-7 {
  width: 58.333333333333333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.666666666666667%;
}

.col-4 {
  width: 33.333333333333333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.666666666666667%;
}

.col-1 {
  width: 8.333333333333333%;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dotted $darkGray;
}

.mw-1200 {
  max-width: 1200px;
}

@media screen and (max-width: 768px) {
  *[class^="col-"] {
    width: 100%;
  }

  ul {
    list-style: outside;
  }

  .mw-1200 {
    max-width: 100%;
  }

  .mobile-order-2 {
    order: 2;
  }
}

.br-4 {
  border-radius: 4px;
}

details > summary {
  list-style-type: none;
}

details summary::-webkit-details-marker {
  display: none;
}

details {
  min-width: 50%;
  margin: 0 auto;
  background: $shadePrimary;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 1rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
}

summary {
  padding: 12px 24px;
  // display: block;
  background: $brandPrimary;
  // padding-left: 2.2rem;
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

// summary:after {
//   content: "+";
//   // border-width: 0.4rem;
//   // border-style: solid;
//   // border-color: transparent transparent transparent #fff;
//   // position: absolute;
//   // top: 12px;
//   // right: 12px;
//   // transform: rotate(0);
//   // transform-origin: 0.2rem 50%;
//   // transition: 0.25s transform ease;
//   font-weight: bold;
//   margin-right: 12px;
// }

// /* THE MAGIC 🧙‍♀️ */
// details[open] > summary:after {
//   content: "-";
//   // transform: rotate(90deg);
// }

details > ul {
  list-style: none;
  padding-left: 2em;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.css-yk16xz-control {
  height: 48px;
}

.css-sk39y4-Mt {
  height: 48px !important;
}

.css-26l3qy-menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 9999 !important;
  box-sizing: border-box;
}

.selectable {
  cursor: pointer;
  border: 1px solid gray;
  &:hover {
    border-color: $hoverPrimary;
  }
}

.selectable.selected {
  border-color: $hoverPrimary;
}
