.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.input {
  border: none;
  background-color: transparent;
}

.input:focus {
  border: none !important;
  background-color: transparent;
}

.scan-button {
  padding: 0;
  border: none;
}

.scan-icon {
  width: 24px;
  height: 24px;
}
