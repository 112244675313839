@import "../styles/variables.scss";

.modal-button {
  margin: 0px;
  max-width: 140px;
  width: auto;
  padding: 6px;
}

.modal {
  background-color: $lightGray;
  position: absolute;
  width: 600px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-color: rebeccapurple;
  background-color: rgba(18, 18, 21, 0.7);
}
