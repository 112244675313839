@import "../styles/variables.scss";
.header {
  background-color: $brandPrimary;
  padding: 6px 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header button {
  padding: 0;
}

.header .button-icon {
  width: 24px;
  height: 24px;
}

.header h2 {
  color: $brandSecondary;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.request-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
}

.request-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 6px;
}

.client {
  width: 260px;
}

.stretch {
  flex: 1;
}

.gray {
  background-color: $lightGray;
}

.trash-button {
  margin: 0;
  padding: 6px;
  border: none;
  background-color: transparent;
  color: $brandPrimary;
}
