@import "variables.scss";

label {
  font-family: "Albert Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $brandBlack;
  margin-bottom: 6px;
}

textarea {
  font-family: "Albert Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $brandBlack;
  margin-bottom: 6px;
}

input {
  width: 217px;
  height: 38px;

  padding: 6px 12px;

  /* Shades/Light Gray */

  border: 1px solid $darkGray;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  &:hover {
    border-color: $hoverPrimary;
  }
  &:focus {
    box-shadow: $inputShadow;
  }
  &:disabled {
    border-color: $lightGray;
    background-color: $lightGray;
  }
  .warning {
    border-color: $warning;
  }
  .success {
    border-color: $success;
  }
  .error {
    border-color: $error;
  }
  .info {
    border-color: $info;
  }
}

select {
  min-width: 217px;
  height: 48px;
  width: 217px;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
}

// .one-line-form {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
// }

// @media screen and (max-width: 768px) {
//     .one-line-form {
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//     }
// }
