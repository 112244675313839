@import "../styles/variables.scss";

.modal-button {
  margin: 6px;
  max-width: 140px;
  width: 100%;
}

.modal {
  // background-color: $lightGray;
  position: absolute;
  padding: 80px;
  // top: 80px;
  left: 50%;
  // right: auto;
  // bottom: auto;
  // // margin-right: -50%;
  transform: translate(-50%, 0);
  // border-radius: 6px;
}

.modal-overlay {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-color: rebeccapurple;
  background-color: rgba(18, 18, 21, 0.7);
  overflow-y: auto;
  height: 100%;
}

.model-content {
  background-color: $lightGray;
  border-radius: 4px;
  padding: 12px;
}

.summary {
  background-color: $lightGray;
  border-radius: 4px;
  padding: 18px 12px;
  width: 600px;

  // margin-top: 6px;
  // margin-bottom: 6px;

  h1 {
    font-size: 26px;
    line-height: 30px;
    margin: 0;
  }
}

.header {
  padding: 6px 12px;
}

.shop-list {
  display: flex;
  flex-direction: column;
}

.shop-summary {
  background-color: $brandSecondary;
  padding: 12px;
  border-radius: 4px;
  margin: 6px 0px;
  h2 {
    font-size: 18px;
    line-height: 20px;
    margin: 0;
  }

  .reference {
    margin-right: 16px;
  }
}

.shop-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.shop-header h2 {
  margin-left: 12px;
}

.shop-info {
  display: flex;
  flex-direction: row;
}

.shop-contact {
  flex: 1;
}

.shop-delivery {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.delivery-line {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  text-align: end;
}
