@media screen and (max-width: 768px) {
    .hide-mobile {
        display: none !important;
    }

    .lock-scroll {
        overflow: hidden;
        touch-action: none;
    }

    .mobile-p-0 {
        padding: 0 !important;
    }

    .mobile-m-0 {
        margin: 0 !important;
    }
}